import React from 'react'
import css from './Button.module.css'

function Button({
  className,
  children,
  expanded,
  narrow,
  inline,
  type = 'default',
  size = 'default',
  loading,
  nativeType = 'button',
  Icon,
  IconPlacement = 'beforeText',
  ...props
}) {
  const cssClasses = [css.button]
  if (type) {
    cssClasses.push(css[type])
  }
  if (size) {
    cssClasses.push(css[size])
  }
  if (expanded) {
    cssClasses.push(css.expanded)
  }
  if (loading) {
    cssClasses.push(css.loading)
  }
  if (className) {
    cssClasses.push(className)
  }
  if (narrow) {
    cssClasses.push(css.narrow)
  }
  if (inline) {
    cssClasses.push(css.inline)
  }
  const cssClass = cssClasses.join(' ').trim()

  return (
    <button className={cssClass} type={nativeType} {...props}>
      {Icon && IconPlacement === 'beforeText' && <Icon className={css.icon} />}
      {children}
      {Icon && IconPlacement === 'afterText' && <Icon className={css.icon} />}
    </button>
  )
}

export default Button
