/* eslint-disable indent */
import React from 'react'
import styles from './MessageBox.module.css'
import { InfoIcon, WarningIconSmall } from './Icons'

function MessageBox({
  type,
  withTitle = false,
  title,
  message,
  linkTo,
  linkText,
}) {
  let cssClass = ''
  let messageIcon
  switch (type) {
    case 'warning':
      cssClass = 'warning'
      messageIcon = (
        <WarningIconSmall color="#E84C05" className={styles.errorIcon} />
      )
      break
    case 'error':
      cssClass = 'error'
      messageIcon = (
        <WarningIconSmall color="#E3000B" className={styles.errorIcon} />
      )
      break
    default:
      cssClass = 'info'
      messageIcon = <InfoIcon color="#652082" className={styles.infoIcon} />
  }

  return (
    <div className={`${styles.container} ${styles[cssClass]}`}>
      {messageIcon}
      <div>
        {withTitle && <h3 className={styles.title}>{title}</h3>}
        <p className={styles.message}>{message}</p>
        {linkTo && linkText && (
          <a
            className={styles.link}
            href={linkTo}
            target="_blank"
            rel="noreferrer"
          >
            {linkText}
          </a>
        )}
      </div>
    </div>
  )
}
export default MessageBox
