import React from 'react'
import Textbox from '../Form/Textbox'
import MediaLibraryUploadInfo from './MediaLibraryUploadInfo'
import { GetCssInput } from '../layout/Input'
import Mode from '../../utilis/Mode'
import css from './MediaLibraryUploadInput.module.css'

function MediaLibraryUploadInput({
  storeId,
  imageUrl,
  onChange,
  mode = 'Unknown',
  label = 'Bild',
  channel,
  aspectRatio,
  className,
}) {
  return (
    <div className={`${css.mediaLibraryUploadInput} ${className}`}>
      <Textbox
        className={GetCssInput(mode)}
        label={label}
        placeholder="Klistra in URL"
        id="image"
        name="image"
        value={imageUrl || ''}
        disabled={mode === Mode.Delete}
        onChange={onChange}
        beforeTextbox={() => (
          <MediaLibraryUploadInfo
            storeId={storeId}
            channel={channel}
            aspect={aspectRatio}
          />
        )}
      />
    </div>
  )
}

export default MediaLibraryUploadInput
