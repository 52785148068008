import React, { useRef } from 'react'
import InputField from '../Form/InputField'
import InputLabel from '../Form/InputLabel'
import { AngleIcon } from './Icons'
import styles from './Dropdown.module.css'
import CheckboxV2 from './CheckboxV2'

function DropdownSelector({
  label,
  placeholder,
  listItems,
  setSelected,
  large,
  beforeDropdown,
  afterDropdown,
  headerText,
  selectedItems,
  dropdownTakeover = false,
  itemType = 'button',
}) {
  const ref = useRef()
  const [open, setOpen] = React.useState(false)
  const [activeItems, setActiveItems] = React.useState(selectedItems)

  function onSelectItem(e, selected) {
    setSelected(e, selected)
  }

  React.useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false)
      }
    }

    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [open])

  React.useEffect(() => {
    setActiveItems(selectedItems)
  }, [selectedItems])

  return (
    <InputField>
      <InputLabel label={label} className={large ? styles.dropdownLabel : ''} />
      {beforeDropdown && beforeDropdown()}
      <div className={large ? styles.largeDropdown : styles.dropdown} ref={ref}>
        <button
          className={[
            open ? styles.openSelected : styles.selected,
            large ? styles.boldSelected : '',
          ].join(' ')}
          onClick={(e) => {
            e.preventDefault()
            setOpen((prev) => !prev)
          }}
        >
          <span>{placeholder}</span>
          <span
            className={[
              open ? styles.openListIcon : styles.closedListIcon,
              large ? styles.red : '',
            ].join(' ')}
          >
            <AngleIcon direction="down" className={styles.angleIcon} />
          </span>
        </button>
        {open ? (
          <ul className={styles.openList}>
            {dropdownTakeover ? (
              <>
                <li
                  onClick={(e) => onSelectItem(e, 'back')}
                  className={styles.listItemHeader}
                >
                  <AngleIcon
                    direction="left"
                    className={styles.angleIconBack}
                  />
                  {headerText}
                </li>
                <li className={styles.listItem}>
                  <div className={styles.checkbox}>
                    Alla {headerText} ({listItems.length}){' '}
                    <CheckboxV2
                      checked={listItems?.every((listItem) =>
                        activeItems.some((id) => listItem.id === id),
                      )}
                      name="active"
                      onClick={(e) => onSelectItem(e, 'all')}
                    />
                  </div>
                </li>
              </>
            ) : (
              <li className={styles.listItemHeader}>{headerText}</li>
            )}
            {listItems.map((item, index) => {
              return (
                <li key={item + index} className={styles.listItem}>
                  {dropdownTakeover || itemType === 'select' ? (
                    <div className={styles.checkbox}>
                      {item.name}
                      <CheckboxV2
                        checked={activeItems?.some(
                          (id) => item.id === id || item.id === id.id,
                        )}
                        name="active"
                        onClick={(e) => onSelectItem(e, item)}
                      />
                    </div>
                  ) : (
                    <button
                      disabled={item.productCount === 0}
                      onClick={(e) => onSelectItem(e, item)}
                    >
                      {`${item.name} ${
                        item.productCount >= 0 && `(${item.productCount})`
                      }`}
                    </button>
                  )}
                </li>
              )
            })}
          </ul>
        ) : null}
      </div>
      {afterDropdown && afterDropdown()}
    </InputField>
  )
}

export default DropdownSelector
