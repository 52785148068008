import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { unregister } from './registerServiceWorker.js'
import RootComponent from './App'

function isIE() {
  return /Trident\/|MSIE/.test(window.navigator.userAgent)
}

if (!isIE()) {
  const rootElement = document.getElementById('root')
  const root = createRoot(rootElement)
  root.render(
    <BrowserRouter>
      <RootComponent />
    </BrowserRouter>,
  )
} else {
  alert('Internet Explorer is not supported!!')
}

unregister()
