import React from 'react'
import css from './Badge.module.css'

function Badge({ children, type = 'default', expanded = false }) {
  var className = css.badge
  if (type !== 'default') {
    className += ` ${css[type]}`
  }
  if (expanded) {
    className += ` ${css.expanded}`
  }

  return <span className={className}>{children}</span>
}

export default Badge
