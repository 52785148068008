/* eslint-disable indent */
import React, { useState, useEffect } from 'react'
import { getServiceData, sendServiceData } from '../../services/HttpClient'
import { ProntoContainer } from '../layout/Containers'
import { defaultNotifications } from '../../utilis/Notifications'
import { useForm } from 'react-hook-form'
import { InfoIcon, NewWindowIcon, TrashIconLined } from '../layout/Icons'
import Placeholder from '../layout/Placeholder'
import CheckboxV2 from '../layout/CheckboxV2'
import BackButton from '../backbutton/BackButton'
import showNotification from '../../utilis/Notifications'
import NotificationTypes from '../../utilis/NotificationTypes'
import Textbox from '../Form/Textbox'
import Dropdown from '../layout/Dropdown'
import ToggleSwitch from '../layout/ToggleSwitch'
import Modal from '../layout/Modal'
import TimeInput from '../layout/TimeInput'
import DatePicker from '../layout/Datepicker'
import styles from './DeliveryProvider.module.css'
import MessageBox from '../layout/MessageBox'
import withTextExplanation from '../../utilis/withTextExplanation'

function ToSelect(data) {
  var list = []
  const keys = Object.keys(data)
  keys.forEach((key) => {
    var item = data[key]
    list.push({ id: key, value: key, name: item })
  })
  return list
}

function findName(data, id) {
  let found = data.find((element) => element.id === id.toString())
  if (found) {
    return found.name
  }
}

function ShouldParseInt(name) {
  if (name === 'providerType' || name === 'serviceProvider') {
    return true
  }
  return false
}

const DeliveryProvider = (props) => {
  const mode = props.mode
  const history = props.history
  const storeId = props.storeId ? props.storeId : props.match.params.storeid
  const deliveryProviderId = props.deliveryProviderId
    ? props.deliveryproviderid
    : props.match.params.deliveryproviderid
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const [loading, setLoading] = useState(false)
  const [timeHandled, setTimeHandled] = useState(false)
  const [withTimeSlots, setWithTimeSlots] = useState(false)
  const [data, setData] = useState({})
  const [providerNames, setProviderNames] = useState([])
  const [providerTypes, setProviderTypes] = useState([])
  const [providerType, setProviderType] = useState({
    id: '',
    value: '',
    name: '',
  })
  const [serviceProvider, setServiceProvider] = useState('')
  const initDate = {
    dayOfWeek: null,
    name: '',
    date: '',
    startTime: '00:00',
    endTime: '00:00',
    isAvailable: false,
  }

  let minDate = new Date()
  minDate.setDate(minDate.getDate() - 1)
  const [modal, setModal] = useState(false)
  const today = new Date()
  const [modalMonthToDisplay, setModalMonthToDisplay] = useState(today)
  const [input, setInput] = useState(initDate)
  const [exceptionalDays, setExceptionalDays] = useState([])
  const [invalidExceptionalDays, setInvalidExceptionalDays] = useState([])
  const [exceptionalInputError, setExceptionalInputError] = useState(false)
  const [alreadyAddedExceptionalDay, setAlreadyAddedExceptionalDay] =
    useState(false)
  const [exceptionalModal, setExceptionalModal] = useState('')
  const [monday, setMonday] = useState({
    dayOfWeek: 'Monday',
    dayNumber: 1,
    startTime: '00:00',
    endTime: '00:00',
    isAvailable: false,
  })
  const [tuesday, setTuesday] = useState({
    dayOfWeek: 'Tuesday',
    dayNumber: 2,
    startTime: '00:00',
    endTime: '00:00',
    isAvailable: false,
  })
  const [wednesday, setWednesday] = useState({
    dayOfWeek: 'Wednesday',
    dayNumber: 3,
    startTime: '00:00',
    endTime: '00:00',
    isAvailable: false,
  })
  const [thursday, setThursday] = useState({
    dayOfWeek: 'Thursday',
    dayNumber: 4,
    startTime: '00:00',
    endTime: '00:00',
    isAvailable: false,
  })
  const [friday, setFriday] = useState({
    dayOfWeek: 'Friday',
    dayNumber: 5,
    startTime: '00:00',
    endTime: '00:00',
    isAvailable: false,
  })
  const [saturday, setSaturday] = useState({
    dayOfWeek: 'Saturday',
    dayNumber: 6,
    startTime: '00:00',
    endTime: '00:00',
    isAvailable: false,
  })
  const [sunday, setSunday] = useState({
    dayOfWeek: 'Sunday',
    dayNumber: 0,
    startTime: '00:00',
    endTime: '00:00',
    isAvailable: false,
  })
  const [validMonday, setValidMonday] = useState(true)
  const [validTuesday, setValidTuesday] = useState(true)
  const [validWednesday, setValidWednesday] = useState(true)
  const [validThursday, setValidThursday] = useState(true)
  const [validFriday, setValidFriday] = useState(true)
  const [validSaturday, setValidSaturday] = useState(true)
  const [validSunday, setValidSunday] = useState(true)
  const [dayInput, setDayInput] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      if (storeId) {
        const providerTypesResponse = await getServiceData(
          'FoodApi',
          'v1',
          `/deliveryProviders/types`,
        )
        let providerResult = await providerTypesResponse.data
        setProviderTypes(
          ToSelect(providerResult.types).map((type) => ({
            ...type,
            name: withTextExplanation(type.name),
          })),
        )
        setProviderNames(ToSelect(providerResult.names))

        if (deliveryProviderId) {
          const deliveryProviderResponse = await getServiceData(
            'FoodApi',
            'v1',
            `/stores/${storeId}/deliveryProviders/${deliveryProviderId}`,
          )
          const result = await deliveryProviderResponse.data

          setData(result)
          reset(result)
          setProviderType((providerType) => ({
            ...providerType,
            value: `${result.providerType}`,
            name: withTextExplanation(
              providerResult.types[result.providerType],
            ),
          }))
          setServiceProvider(result.serviceProvider)

          const availabilityResponse = await getServiceData(
            'FoodApi',
            'v1',
            `/stores/${storeId}/deliveryProviders/${deliveryProviderId}/availabilityHours/`,
          )

          if (availabilityResponse.data.length > 0) {
            setTimeHandled(true)
            if (hasTimeSlots(availabilityResponse.data)) {
              setWithTimeSlots(true)
            }
            const [weekdayAvailabilityHours, exceptionalAvailabilityHours] =
              getAvailabilityHours(availabilityResponse.data)
            if (weekdayAvailabilityHours.length === 7) {
              setMonday(weekdayAvailabilityHours[1])
              setDayInput((prev) => ({
                ...prev,
                monday: weekdayAvailabilityHours[1],
              }))
              setTuesday(weekdayAvailabilityHours[2])
              setDayInput((prev) => ({
                ...prev,
                tuesday: weekdayAvailabilityHours[2],
              }))
              setWednesday(weekdayAvailabilityHours[3])
              setDayInput((prev) => ({
                ...prev,
                wednesday: weekdayAvailabilityHours[3],
              }))
              setThursday(weekdayAvailabilityHours[4])
              setDayInput((prev) => ({
                ...prev,
                thursday: weekdayAvailabilityHours[4],
              }))
              setFriday(weekdayAvailabilityHours[5])
              setDayInput((prev) => ({
                ...prev,
                friday: weekdayAvailabilityHours[5],
              }))
              setSaturday(weekdayAvailabilityHours[6])
              setDayInput((prev) => ({
                ...prev,
                saturday: weekdayAvailabilityHours[6],
              }))
              setSunday(weekdayAvailabilityHours[0])
              setDayInput((prev) => ({
                ...prev,
                sunday: weekdayAvailabilityHours[0],
              }))
            }
            exceptionalAvailabilityHours.length > 0 &&
              setExceptionalDays(exceptionalAvailabilityHours)
          }
        }
      }
    }

    fetchData()
  }, [storeId, deliveryProviderId, reset])

  function redirectToDelivery() {
    history.push(`/stores/${storeId}/deliveryproviders`)
  }

  function hasTimeSlots(days) {
    return days.some((day) => day.startTime !== day.endTime)
  }

  function getAvailabilityHours(availabilityHours) {
    const [weekdays, exceptionalDays] = availabilityHours.reduce(
      (result, day) => {
        result[day.dayOfWeek !== null ? 0 : 1].push(day)
        return result
      },
      [[], []],
    )

    return [weekdays, exceptionalDays]
  }

  function validateTimeInput(day) {
    const timeRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/
    if (day.startTime > day.endTime) {
      return false
    }
    if (!day.startTime.match(timeRegex) || !day.endTime.match(timeRegex)) {
      return false
    }
    return true
  }

  function validateAvailabilityHours() {
    let validAvailabilityHours = true
    if (
      !validateTimeInput(dayInput.sunday) &&
      dayInput.sunday.isAvailable === true
    ) {
      setValidSunday(false)
      validAvailabilityHours = false
    }
    if (
      !validateTimeInput(dayInput.monday) &&
      dayInput.monday.isAvailable === true
    ) {
      setValidMonday(false)
      validAvailabilityHours = false
    }
    if (
      !validateTimeInput(dayInput.tuesday) &&
      dayInput.tuesday.isAvailable === true
    ) {
      setValidTuesday(false)
      validAvailabilityHours = false
    }
    if (
      !validateTimeInput(dayInput.wednesday) &&
      dayInput.wednesday.isAvailable === true
    ) {
      setValidWednesday(false)
      validAvailabilityHours = false
    }
    if (
      !validateTimeInput(dayInput.thursday) &&
      dayInput.thursday.isAvailable === true
    ) {
      setValidThursday(false)
      validAvailabilityHours = false
    }
    if (
      !validateTimeInput(dayInput.friday) &&
      dayInput.friday.isAvailable === true
    ) {
      setValidFriday(false)
      validAvailabilityHours = false
    }
    if (
      !validateTimeInput(dayInput.saturday) &&
      dayInput.saturday.isAvailable === true
    ) {
      setValidSaturday(false)
      validAvailabilityHours = false
    }
    exceptionalDays.map((day) => {
      if (!validateTimeInput(day) && day.isAvailable === true) {
        setInvalidExceptionalDays((prev) => [...prev, day.date])
        validAvailabilityHours = false
      }
      return false
    })
    return validAvailabilityHours
  }

  function handleAvailabilityHours() {
    if (!validateAvailabilityHours()) {
      return
    }

    setTimeHandled(true)
    setModal(false)

    setSunday(dayInput.sunday)
    setMonday(dayInput.monday)
    setTuesday(dayInput.tuesday)
    setWednesday(dayInput.wednesday)
    setThursday(dayInput.thursday)
    setFriday(dayInput.friday)
    setSaturday(dayInput.saturday)

    if (!withTimeSlots) {
      setSunday({ ...dayInput.sunday, startTime: '00:00', endTime: '00:00' })
      setMonday({ ...dayInput.monday, startTime: '00:00', endTime: '00:00' })
      setTuesday({ ...dayInput.tuesday, startTime: '00:00', endTime: '00:00' })
      setWednesday({
        ...dayInput.wednesday,
        startTime: '00:00',
        endTime: '00:00',
      })
      setThursday({
        ...dayInput.thursday,
        startTime: '00:00',
        endTime: '00:00',
      })
      setFriday({ ...dayInput.friday, startTime: '00:00', endTime: '00:00' })
      setSaturday({
        ...dayInput.saturday,
        startTime: '00:00',
        endTime: '00:00',
      })
      exceptionalDays.map((day) => {
        let index = exceptionalDays.findIndex((d) => d.id === day.id)
        exceptionalDays[index] = {
          ...exceptionalDays[index],
          startTime: '00:00',
          endTime: '00:00',
        }
        return setExceptionalDays(exceptionalDays)
      })
    }
  }

  function onUpdateDayInput(value, updateState, day, name) {
    if (name === 'isAvailable' && value === false) {
      updateState((prev) => ({
        ...prev,
        [day]: {
          ...prev[day],
          [name]: value,
          startTime: '00:00',
          endTime: '00:00',
        },
      }))
    } else {
      updateState((prev) => ({
        ...prev,
        [day]: { ...prev[day], [name]: value },
      }))
    }
  }

  function onUpdate(value, updateState, name) {
    if (name === 'isAvailable' && value === false) {
      updateState((prev) => ({
        ...prev,
        [name]: value,
        startTime: '00:00',
        endTime: '00:00',
      }))
    } else {
      updateState((prev) => ({
        ...prev,
        [name]: value,
      }))
    }
  }

  function onUpdateExceptionalItem(value, name, index) {
    let newArr = [...exceptionalDays]
    newArr[index][name] = value
    setExceptionalDays(newArr)
  }

  function getExceptionalDateFromInput(input, existing = {}) {
    return {
      ...existing,
      id: input.id,
      dayOfWeek: null,
      date: input.date instanceof Date ? dateToString(input.date) : input.date,
      name: input.name,
      startTime: input.startTime === '--' ? '00:00' : input.startTime,
      endTime: input.endTime === '--' ? '00:00' : input.endTime,
      isAvailable: input.isAvailable,
    }
  }

  function dateToString(date) {
    const offset = date.getTimezoneOffset()
    date = new Date(date.getTime() - offset * 60 * 1000)
    return date.toISOString().split('T')[0]
  }

  function stringToDate(dateString) {
    return new Date(Date.parse(dateString))
  }

  function onClose() {
    setExceptionalModal('')
    setModal(true)
    setInput(initDate)
    setModalMonthToDisplay(today)
    setAlreadyAddedExceptionalDay(false)
  }

  async function onSubmit(validationData, e) {
    e.preventDefault()
    setLoading(true)
    const availability = [
      sunday,
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      ...exceptionalDays,
    ]
    if (!data.providerType || !data.serviceProvider) {
      showNotification(
        'Leverans',
        'Välj Leverantör och typ..',
        NotificationTypes.ERROR,
      )
      setLoading(false)
      return
    }

    try {
      if (props.mode === 'Edit') {
        editDeliveryProvider(availability)
      }
      if (props.mode === 'Create') {
        createDeliveryProvider(availability)
      }
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  async function createDeliveryProvider(availabilityHours) {
    const response = await sendServiceData(
      'FoodApi',
      'v1',
      `/stores/${storeId}/deliveryProviders`,
      data,
      'POST',
    )
    const deliveryProviderId = await response.data.id

    if (response.ok) {
      if (availabilityHours.length === 0) {
        defaultNotifications.created(Placeholder.AvailabilityHours, data.name)
        redirectToDelivery()
      }

      if (deliveryProviderId && availabilityHours.length > 0) {
        const res = await sendServiceData(
          'FoodApi',
          'v1',
          `/stores/${storeId}/deliveryProviders/${deliveryProviderId}/availabilityHours`,
          availabilityHours,
          'PUT',
        )
        if (res.ok) {
          defaultNotifications.created(Placeholder.AvailabilityHours, data.name)
          redirectToDelivery()
        } else {
          defaultNotifications.error(Placeholder.AvailabilityHours)
        }
      }
    } else {
      defaultNotifications.error(Placeholder.AvailabilityHours)
    }
  }

  async function editDeliveryProvider(availabilityHours) {
    const response = await sendServiceData(
      'FoodApi',
      'v1',
      `/stores/${storeId}/deliveryProviders/${deliveryProviderId}`,
      data,
      'PUT',
    )

    if (response.ok) {
      if (deliveryProviderId && availabilityHours.length > 0) {
        const res = await sendServiceData(
          'FoodApi',
          'v1',
          `/stores/${storeId}/deliveryProviders/${deliveryProviderId}/availabilityHours`,
          availabilityHours,
          'PUT',
        )

        if (res.ok) {
          defaultNotifications.updated(Placeholder.AvailabilityHours, data.name)
          redirectToDelivery()
          return
        } else {
          defaultNotifications.error(Placeholder.AvailabilityHours)
        }
      } else {
        defaultNotifications.updated(Placeholder.AvailabilityHours, data.name)
        redirectToDelivery()
      }
    } else {
      defaultNotifications.error(Placeholder.AvailabilityHours)
    }
  }

  async function deleteDeliveryProvider() {
    if (!window.confirm(`Är du säker på att du vill ta bort "${data.name}"?`)) {
      return
    }

    try {
      const response = await sendServiceData(
        'FoodApi',
        'v1',
        `/stores/${storeId}/deliveryProviders/${deliveryProviderId}`,
        {},
        'DELETE',
      )
      if (response.ok) {
        defaultNotifications.deleted(Placeholder.AvailabilityHours, data.name)
        redirectToDelivery()
      } else {
        defaultNotifications.error(Placeholder.AvailabilityHours)
      }
    } catch (error) {
      console.log(error)
    }
  }
  function handleExceptionalHours(input, mode) {
    if (mode === 'add') {
      if (
        exceptionalDays.some(
          (exludedDate) => exludedDate.date === dateToString(input.date),
        )
      ) {
        setAlreadyAddedExceptionalDay(true)
      } else if (!validateTimeInput(input) && input.isAvailable === true) {
        setExceptionalInputError(true)
      } else {
        setExceptionalDays([
          ...exceptionalDays,
          getExceptionalDateFromInput(input),
        ])
        onClose()
      }
    }
    if (mode === 'edit') {
      if (!validateTimeInput(input) && input.isAvailable === true) {
        setExceptionalInputError(true)
      } else {
        const index = exceptionalDays.findIndex((day) => day.id === input.id)
        let newArr = [...exceptionalDays]
        newArr[index] = getExceptionalDateFromInput(input)
        setExceptionalDays(newArr)
        onClose()
      }
    }
    if (mode === 'delete') {
      deleteExceptionalDay(input)
    }
  }

  async function deleteExceptionalDay(input) {
    if (
      !window.confirm(`Är du säker på att du vill ta bort "${input.name}"?`)
    ) {
      return
    }

    try {
      const response = await sendServiceData(
        'FoodApi',
        'v1',
        `/stores/${storeId}/deliveryProviders/${deliveryProviderId}/availabilityHours/${input.id}`,
        {},
        'DELETE',
      )
      if (response.ok) {
        defaultNotifications.deleted(Placeholder.AvailabilityHours, input.name)
        setExceptionalDays([
          ...exceptionalDays.filter((day) => day.id !== input.id),
        ])
      } else {
        defaultNotifications.error(Placeholder.AvailabilityHours)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleInputChange = (event) => {
    event.persist()
    if (event.target.name === 'active') {
      setData((prev) => ({ ...prev, active: event.target.checked }))
      return
    }

    let value = ShouldParseInt(event.target.name)
      ? parseInt(event.target.value, 10)
      : event.target.value

    setData((prev) => ({ ...prev, [event.target.name]: value }))
  }

  const handleDropdownChange = (type, selected) => {
    switch (type) {
      case 'serviceProvider':
        setServiceProvider(selected)
        break
      case 'providerType':
        setProviderType({ ...selected })
        break
      default:
        break
    }
    data[type] = ShouldParseInt(type)
      ? parseInt(selected.value, 10)
      : selected.value

    setData(data)
  }

  return (
    <div>
      <BackButton backLabel={Placeholder.Delivery} editPage={true} {...props}>
        {mode === 'Create'
          ? 'Lägg till leveransalternativ'
          : `Ändra ${data.name}`}
      </BackButton>
      <ProntoContainer>
        <form
          className="form"
          id="deliveryForm"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="form-section pr-10">
            <Textbox
              label={Placeholder.CustomerTitle}
              placeholder="Ex. Leverans: Mellan 9-11"
              id="name"
              name="name"
              value={data.name}
              onChange={handleInputChange}
              validationRegister={register}
              validation={{
                required: true,
              }}
              errors={errors}
            />
            <Textbox
              label="Förtydligande undertext till kundtitel (valfritt fält)"
              placeholder="Ex. Vi ringer 30 minuter innan leverans"
              id="description"
              name="description"
              value={data.description}
              onChange={handleInputChange}
            />
            <Textbox
              label={`${Placeholder.BongTitle} (Max 11 tecken)`}
              placeholder="Ex. Delidisk"
              id="bongName"
              name="bongName"
              value={data.bongName}
              onChange={handleInputChange}
              maxLength="11"
            />
            <Dropdown
              label={Placeholder.DeliveryProviderType}
              placeholder={
                providerType.name
                  ? providerType.name
                  : findName(providerTypes, providerType.value)
              }
              listItems={providerTypes}
              setSelected={(e, selected) => {
                e.preventDefault()
                handleDropdownChange('providerType', selected)
              }}
              afterDropdown={() =>
                providerType?.name?.includes('ScheduledDelivery') && (
                  <MessageBox
                    type="info"
                    withTitle={true}
                    title="OBS! ScheduledDelivery kräver att payex är aktiverat"
                    message="Säkerställ att payex är aktiverat som betalsätt för catering"
                    linkTo={`${window.location.origin.toString()}/stores/${storeId}/paymentMethods`}
                    linkText={
                      <span className={styles.linkText}>
                        Länk till betalsätt{' '}
                        <NewWindowIcon
                          className={styles.newWindowIcon}
                          size="24px"
                          color="#CF2E05"
                        />
                      </span>
                    }
                  />
                )
              }
            />
            <Dropdown
              label={Placeholder.DeliveryProviderName}
              placeholder={
                serviceProvider.name
                  ? serviceProvider.name
                  : findName(providerNames, serviceProvider)
              }
              listItems={providerNames}
              setSelected={(e, selected) => {
                e.preventDefault()
                handleDropdownChange('serviceProvider', selected)
              }}
            />
            <Textbox
              label={Placeholder.Price}
              placeholder=""
              id="price"
              name="price"
              value={data.price}
              onChange={handleInputChange}
              validationRegister={register}
              validation={{
                required: true,
              }}
              errors={errors}
            />
            <Textbox
              label={Placeholder.PickupDelay}
              placeholder=""
              id="pickupDelay"
              name="pickupDelay"
              value={data.pickupDelay}
              onChange={handleInputChange}
            />
            <Textbox
              label={Placeholder.DeliveryTimeInMinutes}
              placeholder=""
              id="deliveryTimeInMinutes"
              name="deliveryTimeInMinutes"
              value={data.deliveryTimeInMinutes}
              onChange={handleInputChange}
            />
            <Textbox
              label={`${Placeholder.PostalCode} (separera med ,)`}
              placeholder="Ex. 45323, 75943, osv"
              id="postalCodes"
              name="postalCodes"
              value={data.postalCodes}
              onChange={handleInputChange}
            />
            <Textbox
              label="Ange leveranstid (synlig i appen)"
              placeholder="Ex. levereras i expressfart"
              id="deliveryTime"
              name="deliveryTime"
              value={data.deliveryTime}
              onChange={handleInputChange}
            />

            <h3 className={styles.subHeading}>Leveranstider</h3>
            {timeHandled ? (
              <div className={styles.timeGroup}>
                <p>
                  {Placeholder.Monday}:{' '}
                  {monday && monday.isAvailable
                    ? withTimeSlots
                      ? `${monday.startTime} - ${monday.endTime}`
                      : 'Tillgänglig'
                    : 'Ej tillgänglig'}
                </p>
                <p>
                  {Placeholder.Tuesday}:{' '}
                  {tuesday && tuesday.isAvailable
                    ? withTimeSlots
                      ? `${tuesday.startTime} - ${tuesday.endTime}`
                      : 'Tillgänglig'
                    : 'Ej tillgänglig'}
                </p>
                <p>
                  {Placeholder.Wednesday}:{' '}
                  {wednesday && wednesday.isAvailable
                    ? withTimeSlots
                      ? `${wednesday.startTime} - ${wednesday.endTime}`
                      : 'Tillgänglig'
                    : 'Ej tillgänglig'}
                </p>
                <p>
                  {Placeholder.Thursday}:{' '}
                  {thursday && thursday.isAvailable
                    ? withTimeSlots
                      ? `${thursday.startTime} - ${thursday.endTime}`
                      : 'Tillgänglig'
                    : 'Ej tillgänglig'}
                </p>
                <p>
                  {Placeholder.Friday}:{' '}
                  {friday && friday.isAvailable
                    ? withTimeSlots
                      ? `${friday.startTime} - ${friday.endTime}`
                      : 'Tillgänglig'
                    : 'Ej tillgänglig'}
                </p>
                <p>
                  {Placeholder.Saturday}:{' '}
                  {saturday && saturday.isAvailable
                    ? withTimeSlots
                      ? `${saturday.startTime} - ${saturday.endTime}`
                      : 'Tillgänglig'
                    : 'Ej tillgänglig'}
                </p>
                <p>
                  {Placeholder.Sunday}:{' '}
                  {sunday && sunday.isAvailable
                    ? withTimeSlots
                      ? `${sunday.startTime} - ${sunday.endTime}`
                      : 'Tillgänglig'
                    : 'Ej tillgänglig'}
                </p>
                {exceptionalDays.length > 0 && (
                  <div>
                    <h3 className={styles.subHeading}>
                      Avvikande leveransdagar
                    </h3>
                    {exceptionalDays.map((day, index) => {
                      return (
                        <p key={day + index}>
                          {`${day.name} (${day.date}): `}{' '}
                          {day.isAvailable
                            ? withTimeSlots
                              ? `${day.startTime} - ${day.endTime}`
                              : 'Tillgänglig'
                            : 'Ej tillgänglig'}
                        </p>
                      )
                    })}
                  </div>
                )}
              </div>
            ) : (
              <p>
                Inga leveranstider angivna. -{' '}
                <span style={{ fontStyle: 'italic' }}>
                  Hantera leveranstider för att kunna aktivera detta
                  leveransalternativ.
                </span>
              </p>
            )}

            <button
              onClick={(e) => {
                e.preventDefault()
                setModal(true)
                setDayInput({
                  monday: monday,
                  tuesday: tuesday,
                  wednesday: wednesday,
                  thursday: thursday,
                  friday: friday,
                  saturday: saturday,
                  sunday: sunday,
                })
              }}
              className={`${styles.button} ${styles.pink}`}
            >
              Hantera leveranstider
            </button>

            <Modal
              maxWidth="938px"
              minHeight="519px"
              height="100%"
              title={
                data.name
                  ? `Ange leveransdatum och tider - ${data.name}`
                  : 'Ange leveransdatum och tider'
              }
              active={modal}
              onConfirm={() => null}
              onCancel={() => {
                setModal(false)
              }}
              withClose={true}
            >
              <div>
                <h3 className={styles.toggleHeading}>
                  Leveranstider kopplat till leveransalternativ
                </h3>
                <ToggleSwitch
                  label="Aktivera tidsslottar för leverans"
                  id="toggle"
                  checked={withTimeSlots}
                  onChange={() => setWithTimeSlots(!withTimeSlots)}
                />
                {withTimeSlots && (
                  <p className={styles.infoText}>
                    <InfoIcon color="currentColor" />
                    Valbara leveranstider presenteras för kund (med 1h intervall
                    mellan nedan tidsangivelser).
                  </p>
                )}
                <TimeInput
                  title={Placeholder.Monday}
                  deliveryHour={dayInput.monday}
                  withInput={withTimeSlots}
                  valid={validMonday}
                  withRadio={true}
                  onChange={(value, name) => {
                    onUpdateDayInput(value, setDayInput, 'monday', name)
                    setValidMonday(true)
                  }}
                />
                <TimeInput
                  title={Placeholder.Tuesday}
                  deliveryHour={dayInput.tuesday}
                  withInput={withTimeSlots}
                  valid={validTuesday}
                  withRadio={true}
                  onChange={(value, name) => {
                    onUpdateDayInput(value, setDayInput, 'tuesday', name)
                    setValidTuesday(true)
                  }}
                />
                <TimeInput
                  title={Placeholder.Wednesday}
                  deliveryHour={dayInput.wednesday}
                  withInput={withTimeSlots}
                  valid={validWednesday}
                  withRadio={true}
                  onChange={(value, name) => {
                    onUpdateDayInput(value, setDayInput, 'wednesday', name)
                    setValidWednesday(true)
                  }}
                />
                <TimeInput
                  title={Placeholder.Thursday}
                  deliveryHour={dayInput.thursday}
                  withInput={withTimeSlots}
                  valid={validThursday}
                  withRadio={true}
                  onChange={(value, name) => {
                    onUpdateDayInput(value, setDayInput, 'thursday', name)
                    setValidThursday(true)
                  }}
                />
                <TimeInput
                  title={Placeholder.Friday}
                  deliveryHour={dayInput.friday}
                  withInput={withTimeSlots}
                  valid={validFriday}
                  withRadio={true}
                  onChange={(value, name) => {
                    onUpdateDayInput(value, setDayInput, 'friday', name)
                    setValidFriday(true)
                  }}
                />
                <TimeInput
                  title={Placeholder.Saturday}
                  deliveryHour={dayInput.saturday}
                  withInput={withTimeSlots}
                  valid={validSaturday}
                  withRadio={true}
                  onChange={(value, name) => {
                    onUpdateDayInput(value, setDayInput, 'saturday', name)
                    setValidSaturday(true)
                  }}
                />
                <TimeInput
                  title={Placeholder.Sunday}
                  deliveryHour={dayInput.sunday}
                  withInput={withTimeSlots}
                  valid={validSunday}
                  withRadio={true}
                  onChange={(value, name) => {
                    onUpdateDayInput(value, setDayInput, 'sunday', name)
                    setValidSunday(true)
                  }}
                />
              </div>

              <div className={styles.exceptionalHeading}>
                <h2>Avvikande upphämtings-/leveranstider</h2>
                <button
                  className={`${styles.button} ${styles.red}`}
                  onClick={(e) => {
                    e.preventDefault()
                    setExceptionalModal('add')
                    setModal(false)
                  }}
                >
                  + Lägg till avvikande datum
                </button>
              </div>
              <div>
                {exceptionalDays &&
                  exceptionalDays.map((day, index) => {
                    return (
                      <div key={day + index}>
                        <TimeInput
                          title={`${day.name} (${day.date})`}
                          deliveryHour={day}
                          onChange={(value, name) => {
                            onUpdateExceptionalItem(value, name, index)
                            setInvalidExceptionalDays(
                              invalidExceptionalDays.filter(
                                (d) => d !== day.date,
                              ),
                            )
                          }}
                          onEditClick={(e) => {
                            setModal(false)
                            setExceptionalModal('edit')
                            setInput(day)
                            setModalMonthToDisplay(new Date(day.date))
                            e.preventDefault()
                          }}
                          withChange={true}
                          withInput={withTimeSlots}
                          valid={!invalidExceptionalDays.includes(day.date)}
                          withRadio={true}
                          alreadyAddedExceptionalDay={
                            alreadyAddedExceptionalDay
                          }
                        />
                      </div>
                    )
                  })}
              </div>
              <button
                className={styles.addButton}
                type="button"
                onClick={handleAvailabilityHours}
              >
                Ok
              </button>
            </Modal>

            <Modal
              maxWidth="938px"
              minHeight="519px"
              height="100%"
              title={
                exceptionalModal === 'add'
                  ? 'Lägg till avvikande datum'
                  : 'Ändra avvikande datum'
              }
              active={exceptionalModal}
              onConfirm={() => null}
              onCancel={() => onClose()}
              withClose={true}
            >
              <div className={styles.container}>
                {exceptionalModal === 'edit' && (
                  <button
                    className={styles.deleteButton}
                    onClick={() => {
                      handleExceptionalHours(input, 'delete')
                      onClose()
                    }}
                  >
                    <TrashIconLined color="#E3000B" />
                    <span className={styles.deleteText}>Ta bort</span>
                  </button>
                )}
                <div>
                  <p className={styles.exceptionalLabel}>{Placeholder.Name}</p>
                  <input
                    id="nameExceptional"
                    placeholder='Ex. "Julafton"'
                    className={styles.nameInput}
                    type="text"
                    value={input.name}
                    onChange={(e) => onUpdate(e.target.value, setInput, 'name')}
                  />
                </div>

                <p className={styles.exceptionalLabel}>Datum</p>
                <DatePicker
                  minDate={minDate}
                  date={modalMonthToDisplay}
                  selected={stringToDate(input.date)}
                  onDateSelected={(date) => {
                    onUpdate(date.selected, setInput, 'date')
                  }}
                />
                <TimeInput
                  title={Placeholder.Delivery}
                  deliveryHour={input}
                  withInput={withTimeSlots}
                  valid={!exceptionalInputError}
                  withRadio={true}
                  onChange={(value, name) => {
                    onUpdate(value, setInput, name)
                    setExceptionalInputError(false)
                  }}
                />
                {alreadyAddedExceptionalDay && (
                  <div>
                    <p style={{ color: 'red' }}>
                      Datumet finns redan i listan med avvikande leveransdagar
                    </p>
                  </div>
                )}
                <div className={styles.modalFooter}>
                  <button
                    className={styles.addButton}
                    onClick={(e) => {
                      e.preventDefault()
                      exceptionalModal === 'add'
                        ? handleExceptionalHours(input, 'add')
                        : handleExceptionalHours(input, 'edit')
                    }}
                    disabled={!(input.name && input.date)}
                  >
                    {exceptionalModal === 'add' ? 'Lägg till' : 'Ok'}
                  </button>
                </div>
              </div>
            </Modal>

            <div className={styles.checkbox}>
              <CheckboxV2
                label={Placeholder.Active}
                disabled={!timeHandled}
                checked={data.active}
                name="active"
                onClick={handleInputChange}
              />
            </div>
          </div>
        </form>
        <div className={styles.buttonGroup}>
          {deliveryProviderId ? (
            <button
              className={styles.deleteButton}
              onClick={deleteDeliveryProvider}
            >
              <TrashIconLined color="#E3000B" />
              <span className={styles.deleteText}>
                Ta bort leveransalternativ
              </span>
            </button>
          ) : (
            <div></div>
          )}
          <div>
            <button
              className={`${styles.button} ${styles.pink} ${styles.cancel}`}
              onClick={redirectToDelivery}
              disabled={loading}
            >
              Avbryt
            </button>
            <button
              className={`${styles.button} ${styles.red}`}
              disabled={loading}
              type="submit"
              form="deliveryForm"
            >
              Spara
            </button>
          </div>
        </div>
      </ProntoContainer>
    </div>
  )
}

export default DeliveryProvider
