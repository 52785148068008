/* eslint-disable indent */
import React from 'react'
import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import isBetween from 'dayjs/plugin/isBetween'
import './Datepicker.css'
import { AngleIcon } from './Icons'

dayjs.extend(weekday)
dayjs.extend(isBetween)

function DatePicker({
  minDate,
  maxDate,
  firstDayOfWeek = 1,
  onDateSelected,
  selected,
  date,
}) {
  const selectedDate = selected ? dayjs(selected) : null
  const currentDate = dayjs(date || new Date())
  const [viewDate, setViewDate] = React.useState(currentDate)

  const monthNamesShort = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'Maj',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Dec',
  ]
  const monthNamesLong = [
    'Januari',
    'Februari',
    'Mars',
    'April',
    'Maj',
    'Juni',
    'Juli',
    'Augusti',
    'September',
    'Oktober',
    'November',
    'December',
  ]
  const weekdayNamesShort = ['Mån', 'Tis', 'Ons', 'Tors', 'Fre', 'Lör', 'Sön']

  // Generate calendar data for current month
  const generateCalendarDays = () => {
    const start = viewDate.startOf('month').weekday(firstDayOfWeek)
    const end = viewDate.endOf('month').weekday(7 + firstDayOfWeek - 1)
    const days = []
    let day = start

    while (day.isBefore(end) || day.isSame(end, 'day')) {
      days.push(day)
      day = day.add(1, 'day')
    }

    // Split days into weeks
    const weeks = []
    let week = []
    days.forEach((d) => {
      week.push(d)
      if (week.length === 7) {
        weeks.push(week)
        week = []
      }
    })
    if (week.length > 0) weeks.push(week)

    return weeks
  }

  const isSelectable = (date) => {
    if (minDate && date.isBefore(dayjs(minDate), 'day')) return false
    if (maxDate && date.isAfter(dayjs(maxDate), 'day')) return false
    return true
  }

  const handleDateClick = (date) => {
    if (!isSelectable(date)) return
    onDateSelected({ selected: date.toDate() })
  }

  const handleMonthNavigate = (direction) => {
    setViewDate(viewDate.add(direction, 'month'))
  }

  return (
    <div className="wrapper">
      <div>
        <div className="datePicker">
          <div className="calendarHeading">
            <button
              className="prevButton"
              onClick={() => handleMonthNavigate(-1)}
              aria-label="Byt månad bakåt"
            >
              <span className="hidden">Prev</span>
              <span className="prev">
                <AngleIcon direction="left" className="prevIcon" />
              </span>
            </button>
            <span className="monthYear" aria-hidden="true">
              {monthNamesShort[viewDate.month()]} {viewDate.year()}
            </span>
            <span className="hidden">
              {`${monthNamesLong[viewDate.month()]} ${viewDate.year()}`}
            </span>
            <button
              className="nextButton"
              onClick={() => handleMonthNavigate(1)}
              aria-label="Byt månad framåt"
            >
              <span className="hidden">Next</span>
              <span className="next">
                <AngleIcon direction="right" className="nextIcon" />
              </span>
            </button>
          </div>

          <div className="weekdays" aria-hidden="true">
            {weekdayNamesShort.map((weekday) => (
              <div className="weekdayLabel" key={weekday}>
                {weekday}
              </div>
            ))}
          </div>

          <div className="monthSquares">
            {generateCalendarDays().map((week, weekIndex) =>
              week.map((dateObj, index) => {
                const key = `${dateObj.format(
                  'YYYY-MM-DD',
                )}-${weekIndex}-${index}`
                const isCurrentMonth = dateObj.month() === viewDate.month()
                const isSelected =
                  selectedDate && dateObj.isSame(selectedDate, 'day')
                const selectable = isSelectable(dateObj) && isCurrentMonth

                return (
                  <button
                    className={
                      !selectable
                        ? 'notSelectableButton'
                        : isSelected
                        ? 'squareButtonSelected'
                        : 'squareButton'
                    }
                    key={key}
                    onClick={() => handleDateClick(dateObj)}
                    disabled={!selectable}
                  >
                    <span className="dateButton">{dateObj.date()}</span>
                  </button>
                )
              }),
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DatePicker
