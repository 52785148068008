import React, { useState, useEffect } from 'react'
import { getData, postData } from '../../services/Ajax'
import Placeholder from '../layout/Placeholder'
import { ProntoContainer } from '../layout/Containers'
import Mode from '../../utilis/Mode'
import { GetCssInput } from '../layout/Input'
import { ClickButton, SaveButton } from '../layout/Button'
import Checkbox from '../layout/Checkbox'
import BackButton from '../backbutton/BackButton'
import SelectList from '../Form/SelectList'
import { getServiceData } from '../../services/HttpClient'

const MenuItem = (props) => {
  const mode = props.mode
  const history = props.history
  const storeId = props.match.params.storeid
  // const restaurantId = props.match.params.restaurantid;
  const menuId = props.match.params.menuid
  const itemId = props.match.params.itemid
  const initialData = {
    menuId: menuId,
    taxCode: 2,
    name: '',
    imageUrl: '',
    orderingNumber: 0,
    category: null,
    price: 0,
    ean: null,
    nutrientFacts: '',
    description: '',
  }
  const [data, setData] = useState(initialData)
  const [menu, setMenu] = useState({})
  const taxCodes = [
    { id: 1, value: 1, name: '25 %' },
    { id: 2, value: 2, name: '12 %' },
    { id: 3, value: 3, name: '6 %' },
  ]

  useEffect(() => {
    const fetchData = async () => {
      if (itemId) {
        const response = await getData('/api/menuitem/' + itemId)
        const result = await response
        setData(result)
      }

      if (menuId) {
        const menuResponse = await getData('/api/menu/' + menuId)
        const menuResult = await menuResponse
        setMenu(menuResult)
      }
    }

    fetchData()
  }, [])
  const getPostUrl = () => {
    if (mode === Mode.Edit) {
      return '/api/menuitem/edit'
    }
    if (mode === Mode.Create) {
      return '/api/menuitem/create'
    }
    if (mode === Mode.Delete) {
      return '/api/menuitem/delete'
    }

    return undefined
  }

  const hasPayexActivated = async () => {
    const response = await getServiceData(
      'PaymentApi',
      'v1',
      `/stores/${storeId}/paymentMethods`,
    )

    if (response.ok) {
      const result = await response.data
      return result.some(
        (method) =>
          (method.paymentProviderType?.toLowerCase() === 'payex' ||
            method.paymentProviderType?.toLowerCase() === 'terminal') &&
          method.active,
      )
    }
    return false
  }

  const handlePostData = async () => {
    const ean = data.Ean ? data.Ean : data.ean
    const eanLength = ean ? ean.toString().length : 0
    const isValidEan = eanLength === 0 || (eanLength <= 13 && eanLength >= 8)
    if (
      !isValidEan ||
      (data.active &&
        eanLength !== 13 &&
        eanLength !== 0 &&
        (await hasPayexActivated()))
    ) {
      alert('Ean koden måste bestå av 13st siffror!')
      return
    }
    if (data.price === undefined || data.price === null || data.price === '') {
      alert('Pris saknas')
      return
    }
    if (
      data.orderingNumber === undefined ||
      data.orderingNumber === null ||
      data.orderingNumber === ''
    ) {
      alert('Sorteringsnummer saknas')
      return
    }
    const url = getPostUrl()
    if (url) {
      const response = await postData(url, data)
      return await response
    } else {
      console.error('url not defined')
    }
  }

  const handleInputChange = (event) => {
    event.persist()

    setData({
      ...data,
      [event.target.name]: event.target.value,
    })
  }

  return (
    <div>
      <BackButton backLabel={menu.name} editPage={true} {...props}>
        {data.name}
      </BackButton>
      <ProntoContainer>
        <div className="form">
          <div className="form-section c-6 pr-10">
            <input
              className={GetCssInput(mode)}
              type="text"
              onChange={handleInputChange}
              name="name"
              value={data.name ?? ''}
              placeholder={Placeholder.Name}
            />
            <input
              className={GetCssInput(mode)}
              type="text"
              onChange={handleInputChange}
              name="imageUrl"
              value={data.imageUrl ?? ''}
              placeholder={Placeholder.ImageUrl}
            />
            <input
              className={GetCssInput(mode)}
              type="number"
              onChange={handleInputChange}
              name="orderingNumber"
              value={data.orderingNumber ?? ''}
              placeholder={Placeholder.Sort}
            />
            <input
              className={GetCssInput(mode)}
              type="number"
              onChange={handleInputChange}
              name="category"
              value={data.category ?? ''}
              placeholder={Placeholder.Category}
            />
            <input
              className={GetCssInput(mode)}
              type="number"
              onChange={handleInputChange}
              name="price"
              value={data.price ?? ''}
              placeholder={Placeholder.Price}
            />
            {taxCodes && (
              <div>
                <SelectList
                  options={taxCodes}
                  optionValueField="value"
                  name="taxCode"
                  onChange={handleInputChange}
                  defaultOption="Välj momssats"
                  value={data.taxCode}
                />
              </div>
            )}
            <input
              className={GetCssInput(mode)}
              type="number"
              onChange={handleInputChange}
              name="ean"
              value={data.ean ?? ''}
              placeholder={Placeholder.Ean}
            />
            <input
              className={GetCssInput(mode)}
              type="text"
              onChange={handleInputChange}
              name="nutrientFacts"
              value={data.nutrientFacts ?? ''}
              placeholder="Näringsinnehåll"
            />
            <textarea
              name="description"
              className={GetCssInput(mode) + ' input-textarea'}
              onChange={handleInputChange}
              value={data.description ?? ''}
              placeholder={Placeholder.Description}
            ></textarea>

            <div className="row">
              {data.id && (
                <Checkbox
                  checked={data.active}
                  name="active"
                  handleEvent={handleInputChange}
                />
              )}
              {!data.id && (
                <Checkbox
                  checked={data.active}
                  name="active"
                  handleEvent={handleInputChange}
                />
              )}
              <span className="label form-label">{Placeholder.Active}</span>
            </div>

            <div className="row">
              <SaveButton
                className="btn--black mr-10"
                title={
                  Mode.Delete === mode
                    ? Placeholder.Delete
                    : Placeholder.SaveButton
                }
                onClick={handlePostData}
              />
              <ClickButton
                className="btn--white"
                title={Placeholder.Cancel}
                onClick={history.goBack}
              />
            </div>
          </div>
        </div>
      </ProntoContainer>
    </div>
  )
}

export default MenuItem
